<template>
  <div id="honorvideo">
    <Header now-nav="/relation" ref="Header"></Header>
    <div class="headerBox">
      <img :src="baseUrl+'imges/honorvideo/MobileHonorvideo.png'" alt="" class="MobileBG">
      <img :src="baseUrl+'imges/honorvideo/honorvideoBG.png'" alt="" class="imgHonorvideoHead">
      <h4  data-aos="fade-down" data-aos-duration="1000">公司视频</h4>
<!--      <p  data-aos="fade-down" data-aos-duration="1000">蓉视通科技专注于智慧管廊领域的研究与开发，多次荣获各机构颁发荣誉与专利证书，成为受人瞩目的工业物联网软硬件制造商。</p>-->
      <p  data-aos="fade-down" data-aos-duration="1000" class="PC">蓉视通科技专注技术创新，先后获得几十项发明专利及各类荣誉证书。</p>
      <p  data-aos="fade-down" data-aos-duration="1000" class="Mobile">蓉视通科技专注技术创新，<br>先后获得几十项发明专利及各类荣誉证书。</p>
    </div>

    <!--  内容区  -->
    <div class="contentBox" >
      <div class="navHead">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="navHead_text">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/about' }">公司简介</el-breadcrumb-item>
          <el-breadcrumb-item>公司视频</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="VideoBox">
        <div class="VideoBox_list">
          <video preload loop muted data-keepplaying poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg1.png">
            <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
          </video>
          <div class="startVideo" @click="startPlay(0)">
            <img :src="baseUrl+'imges/about/start.png'" alt="">
            <p>点击观看视频</p>
          </div>
          <p>蓉视通科技企宣视频</p>
<!--          <div class="num">-->
<!--            <span>上一页</span>-->
<!--            <p>1</p>-->
<!--            <span>下一页</span>-->
<!--          </div>-->
        </div>
        <div class="VideoBox_list" style="display: none">
          <video preload loop muted data-keepplaying poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg2.png">
            <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
          </video>
          <div class="startVideo" @click="startPlay(2)">
            <img :src="baseUrl+'imges/about/start.png'" alt="">
            <p>点击观看视频</p>
          </div>
          <p>蓉视通宣传视频2</p>
        </div>
        <div class="VideoBox_list" style="display: none">
          <video preload loop muted data-keepplaying poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg3.png">
            <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
          </video>
          <div class="startVideo" @click="startPlay(3)">
            <img :src="baseUrl+'imges/about/start.png'" alt="">
            <p>点击观看视频</p>
          </div>
          <p>蓉视通宣传视频3</p>
        </div>
      </div>

      <div v-if="playVideoId === 1" class="video-layer">
        <div class="container">
          <p @click="playVideoId=0">×</p>
          <div>
            <video controls autoplay poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg1.png">
              <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
            </video>
          </div>
        </div>
      </div>
      <div v-if="playVideoId === 2" class="video-layer">
        <div class="container">
          <p @click="playVideoId=0">×</p>
          <div>
            <video controls autoplay poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg2.png">
              <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
            </video>
          </div>
        </div>
      </div>
      <div v-if="playVideoId === 3" class="video-layer">
        <div class="container">
          <p @click="playVideoId=0">×</p>
          <div>
            <video controls autoplay poster="http://www.cd-rst.top:18000/static/imges/honorvideo/videoImg3.png">
              <source :src="baseUrl+'10.20RGM轨道巡检机器人.mp4'">
            </video>
          </div>
        </div>
      </div>

    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Honorvideo",
  data() {
    return {
      playVideoId: 0,
    }
  },
  components: {
    Header,
    Footer,
  },
  mounted(){
  },
  methods: {
    startPlay(e){
      this.playVideoId = e
    },
  }
}
</script>

<style lang="less" scoped>
#honorvideo {
  .PC{
    display: block;
  }
  .Mobile{
    display: none;
  }
  background: #FFFFFF;
  min-width: 1250px;
  .headerBox {
    width: 100%;
    height: 580px;
    position: relative;
    min-width: 1250px;
    h4{
      position: absolute;
      left: 18.7vw;
      top: 312px;
      font-family: Medium;
      font-size: 50px;
      font-weight: 500;
      line-height: 17px;
      color: #FFFFFF;
    }
    p{
      position: absolute;
      left: 18.7vw;
      top: 365px;
      width: 508px;
      text-align: left;
      font-family: Regular;
      font-size: 16px;
      line-height: 36px;
      color: #F2F3FE;
    }
    .imgHonorvideoHead {
      display: block;
      width: 100%;
      height: 100%;
    }
    .MobileBG{
      display: none;
    }
  }
  .contentBox {
    .navHead{
      width: 100%;
      height: 60px;
      background: #F5F5F5;
      .navHead_text{
        line-height: 60px;
        margin-left: 8vw;
      }
    }
    .VideoBox{
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      width: 1596px;
      //height: 748px;
      margin: 70px auto 107px;
      overflow-x: hidden;
      .VideoBox_list{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 508px;
        height: 329px;
        text-align: left;
        margin-bottom: 70px;
        video{
          width: 508px;
          height: 286px;
        }
        .startVideo{
          position: absolute;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          text-align: center;
          img{
            width: 50px;
            height: 50px;
            margin-bottom: 10px;
          }
          p{
            font-family: Medium;
            font-size: 16px;
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        p{
          font-family: Regular;
          font-size: 20px;
          font-weight: normal;
          color: #3D3D3D;
        }
      }
    }
    //遮罩层展示Video
    .video-layer{
      position: fixed;
      z-index: 100;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.7);
      .container{
        position: relative;
        width: 1250px;
        height: 704px;
        top: 100px;
        left: 50%;
        transform: translateX(-50%);
        p{
          position: absolute;
          top: 0;
          right: 15px;
          font-size: 40px;
          color: #FFFFFF;
          font-family: Regular;
          cursor: pointer;
          z-index: 1;
        }
        div{
          video{
            width: 100%;
          }
        }
      }
    }

    .el-breadcrumb{
      font-size: 14px !important;
    }
    .el-menu-item{
      font-size: 14px;
      font-family: Regular;
      font-weight: 500;
      color: #000000;
      margin-bottom: 12px;
    }
  }
}

@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #honorvideo{
    .PC{
      display: none;
    }
    .Mobile{
      display: block;
      margin-top: 10px;
    }
    background: #FFFFFF;
    width: 100%;
    height: 100%;

    //头部区域
    .headerBox{
      position: relative;
      margin-top: 50px;
      width: 100%;
      height: 100%;
      .imgHonorvideoHead{
        display: none;
      }
      .MobileBG{
        display: block;
        width: 100%;
      }
      h4{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 67px;
        width: 133px;
        height: 26px;
        font-family: Medium;
        font-size: 26px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
      }
      p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 99px;
        width: 323px;
        height: 52px;
        font-family: Regular;
        font-size: 10px;
        font-weight: normal;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0em;
        color: #F2F3FE;
      }
    }

    //视频导航栏
    .contentBox {
      width: 100%;
      height: 100%;
      background-color: #fff;
      .navHead {
        width: 100%;
        height: 50px;
        background: #F5F5F5;
        .navHead_text{
          line-height: 50px;
          margin-left: 8vw;
          font-family: Regular;
          font-size: 12px !important;
          font-weight: normal;
          letter-spacing: 0.05em;
        }
      }
      .VideoBox{
        display: flex;
        flex-direction: column;
        justify-content: left;
        width: 90%;
        height: 100%;
        margin-top: 30px;
        .VideoBox_list{
          width: 100%;
          height: 217px;
          margin-bottom: 40px;
          video{
            width: 100%;
            height: 100%;
            margin-bottom: 9px;
          }
          .startVideo{
            top: 80px;
            img{
              width: 30px;
              height: 30px;
              margin-bottom: 10px;
            }
            p{
              font-family: Medium;
              font-size: 10px;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
          p{
            text-align: left;
            font-family: Regular;
            font-size: 16px;
            font-weight: normal;
            color: #3D3D3D;
            line-height: 17px;
            letter-spacing: 0em;
          }
        }
      }
      //遮罩层展示Video
      .video-layer{
        position: fixed;
        z-index: 100;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 1);
        .container{
          position: relative;
          width: 100%;
          top: 100px;
          left: 50%;
          transform: translateX(-50%);
          p{
            position: absolute;
            top: 0;
            right: 15px;
            font-size: 40px;
            color: #FFFFFF;
            font-family: Regular;
            cursor: pointer;
            z-index: 1;
          }
          div{
            video{
              width: 100%;
            }
          }
        }
      }
    }
  }
}

</style>
